import { socket } from "../socket";

const channelToSubscription = new Map();

socket.addEventListener('open', () => {
	console.log('[socket] Connected');
});

socket.addEventListener('close', (reason) => {
	console.log('[socket] Disconnected:', reason);
});

socket.addEventListener('error', (error) => {
	console.log('[socket] Error:', error);
});

socket.addEventListener('message', (message) => {
	const data = JSON.parse(message);

  if (data.type === 'swap') {
    // Handle real-time price updates
    const { tokenAddress, network, tokenPrice, date } = data.data;
    const channelString = `${network}:${tokenAddress}`;
    const subscriptionItem = channelToSubscription.get(channelString);
    
    if (subscriptionItem) {
      const bar = {
        time: date * 1000, // Convert to milliseconds
        open: parseFloat(tokenPrice),
        high: parseFloat(tokenPrice),
        low: parseFloat(tokenPrice),
        close: parseFloat(tokenPrice),
      };
      
      const lastBar = subscriptionItem.lastBar;
      
      if (lastBar && lastBar.time === bar.time) {
        // Update the existing bar
        bar.open = lastBar.open;
        bar.high = Math.max(lastBar.high, bar.high);
        bar.low = Math.min(lastBar.low, bar.low);
      }
      
      subscriptionItem.lastBar = bar;
      
      // Notify all subscribers
      subscriptionItem.handlers.forEach(handler => handler.callback(bar));
    }
  }
});

// function getNextDailyBarTime(barTime) {
// 	const date = new Date(barTime * 1000);
// 	date.setDate(date.getDate() + 1);
// 	return date.getTime() / 1000;
// }

export function subscribeOnStream(
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscriberUID,
  onResetCacheNeededCallback,
  lastBar
) {
  const channelString = `${symbolInfo.network}:${symbolInfo.address}`;
  const handler = {
    id: subscriberUID,
    callback: onRealtimeCallback,
  };
  let subscriptionItem = channelToSubscription.get(channelString);
  if (subscriptionItem) {
    // Already subscribed to the channel, use the existing subscription
    subscriptionItem.handlers.push(handler);
    return;
  }
  subscriptionItem = {
    subscriberUID,
    resolution,
    lastBar,
    handlers: [handler],
  };
  channelToSubscription.set(channelString, subscriptionItem);
  
  // Send subscription request to the server
  socket.emit('subscribe', { tokenAddress: symbolInfo.address, network: symbolInfo.network });
}

export function unsubscribeFromStream(subscriberUID) {
  for (const channelString of channelToSubscription.keys()) {
    const subscriptionItem = channelToSubscription.get(channelString);
    const handlerIndex = subscriptionItem.handlers.findIndex(
      (handler) => handler.id === subscriberUID
    );

    if (handlerIndex !== -1) {
      // Remove from handlers
      subscriptionItem.handlers.splice(handlerIndex, 1);

      if (subscriptionItem.handlers.length === 0) {
        // Unsubscribe from the channel if it was the last handler
        channelToSubscription.delete(channelString);
        
        // Send unsubscription request to the server
        const [network, tokenAddress] = channelString.split(':');
        socket.emit('unsubscribe', { tokenAddress, network });
        break;
      }
    }
  }
}
