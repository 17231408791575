import { Accordion, AccordionDetails, AccordionSummary, Avatar, Slide, Box, Button, FormControl, MenuItem, Select, SelectChangeEvent, styled, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Card, CardContent, Paper, IconButton, InputBase, Divider, Skeleton } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Image } from "@mui/icons-material";
import { useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider, useSwitchNetwork } from "@web3modal/ethers/react";
import { toast } from 'react-toastify';

export default function CreateCurvePage() {
    const [network, setNetwork] = React.useState('Sei');
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const { open } = useWeb3Modal();
    const { switchNetwork } = useSwitchNetwork()
    

    const [myInviteCode, setMyInviteCode] = React.useState("18235");
    const [referralCode, setReferralCode] = React.useState("");
    const [score, setScore] = React.useState(0);
    const [description, setDescription] = React.useState(`
    Referral description 

    Participants in our referral program earn points based on their trading volume, with an additional bonus for referring others.
    
    For every $1 traded:
    
    1. The trader earns 0.001 points 
    2. The referrer receives a 20% bonus based on the referred trader's volume, earning 0.0002 points for every $1 traded by the referred trader.
    `);

    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {
        const init = async() => {
            if (!address) {
                return;
            }
            /**
             *  Request:  walletAddress
             *  Response: myInviteCode, referralCode, score
             */
            const headers = { 
                'Access-Control-Allow-Origin': '*',
                'x-api-key': process.env.REACT_APP_AUTH_KEY,
                'x-user-identifier': process.env.REACT_APP_USER_ID
            };
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/referral/${address}`, {headers: headers});
                setMyInviteCode(response.data.inviteCode);
                if (parseInt(response.data.referralCode) == 0) {
                    setReferralCode("");
                } else {
                    setReferralCode(response.data.referralCode);
                }
                setScore(parseFloat(response.data.score));
            } catch(e) {
                console.log("referral--error-", e);
            }

        }
        init();
    }, [chainId, address])
 
    const handleSave = async() => {
        // save or update referral informations
        if (!address) {
            return;
        }
        if (myInviteCode == referralCode) {
            toast.error('It cannot be your invite code!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            return;
        }
        const body = {
            walletAddress: address,
            referralCode: referralCode
        }
        const headers = { 
            'Access-Control-Allow-Origin': '*',
            'x-api-key': process.env.REACT_APP_AUTH_KEY,
            'x-user-identifier': process.env.REACT_APP_USER_ID
        };
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/referral`, body, {headers: headers});
        if (response.status == 500) {
            toast.error('Saving Failed', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        } else {
            toast.success('Saving succeed!!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        }
    };
 

    return (
        <Box>
            <Grid2 container spacing={2}>
                <Grid2 xs={12} sm={6} mx='auto'>
                    <Box sx={{ width: '80%', marginLeft: 'auto', marginRight: 'auto', marginTop: '1rem' }}>
                        <Box display='flex' alignItems='center' justifyContent='space-between' mb="1rem">
                            <Typography sx={{ fontSize: { sm: 36, xs: 20 } }}>Referral System</Typography>
                        </Box>
                        <TextField id="outlined-basic" sx={{ mb: '1rem' }} label="My invite code" variant="outlined" fullWidth value={myInviteCode} disabled={true}/>
                        <TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Referral Code" variant="outlined" fullWidth value={referralCode} onChange={(e) => setReferralCode(e.target.value)} />
                        <TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Referral System Overview" variant="outlined" disabled={true} multiline rows={12} fullWidth value={description}  />
                        
                        {isLoading === true && <Skeleton variant="circular" width={100} height={100} sx={{ mb: '1rem' }} />}

                        
                        <Button variant="contained" sx={{ background: '#9E1F63', color: 'white' }} fullWidth onClick={handleSave}>Save</Button>
                    </Box>
                </Grid2>
            </Grid2>
        
        </Box>
    )
}