import NewCurvsIcon from '../assets/images/new.svg';
import WalletIcon from '../assets/images/wallet.svg';
import DeployIcon from '../assets/images/deploy.svg'
import ReferralIcon from '../assets/images/referral.svg'
import BridgeIcon from '../assets/images/bridge.svg'

export const MainRouter = {
    main: [
        {title: "New Curves", icon: NewCurvsIcon,  path: "/"},
        {title: "My Curves", icon: WalletIcon, path: "/my_curves"},
        {title: "Deploy a Curve", icon: DeployIcon, path: "/create_curves"},
        {title: "Bridge", icon: BridgeIcon, path: "/bridge"},
        {title: "Referral Code", icon: ReferralIcon, path: "/referral"},
    ],
}