import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  IconButton,
  Divider,
  Tooltip,
  Avatar,
  FormControl,
  CircularProgress,
  Collapse,
  FormControlLabel,
  Switch,
  Paper,
  Popover,
  Slider,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Link,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { styled } from "@mui/material/styles";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import SettingsIcon from "@mui/icons-material/Settings";
import HistoryIcon from "@mui/icons-material/History";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { ethers } from "ethers";
import axios from "axios";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { BrowserProvider, Eip1193Provider } from "ethers";
import { toast } from "react-toastify";
import tokensData from "../assets/tokens";

const API_BASE_URL = process.env.REACT_APP_API_URL_BRIDGE;
const PARTNERID = new URLSearchParams({
  partnerId: "seifun"
});

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  marginTop: theme.spacing(4),
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[10],
  background: theme.palette.background.paper,
}));

const TokenSelect = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    display: "flex",
    alignItems: "center",
  },
}));

const RouteStep = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minWidth: 80,
  maxWidth: 120,
}));

interface Token {
  chainId?: number;
  name: string;
  symbol: string;
  address: string;
  decimals: number;
  logoURI?: string | null;
}

interface TokenAmount extends Token {
  amount: string;
}

interface Chain {
  id: number;
  name: string;
  icon: string;
  explorer?: string;
}

interface TokensByChain {
  [chainId: number]: Token[];
}

interface SwapTx {
  chainId: number;
  data: string;
  to: string;
  value: string;
  functionSelector?: string;
}

interface SwapDetails {
  kind: "crosschain-swap" | "onchain-swap" | "wrap" | "unwrap" | "bridge";
  fee?: TokenAmount;
  priceImpact: string;
  tokenAmountOut: TokenAmount;
  tokenAmountOutMin?: TokenAmount;
  tx: SwapTx;
  amountInUsd?: TokenAmount;
  approveTo: string;
  route: Token[];
  outTradeType?: string;
  inTradeType?: string;
  type: "evm" | "tron";
  rewards: TokenAmount[];
}

const tokens= tokensData;

const SymbiosisBridge = () => {
  const [chains, setChains] = useState<Chain[] | null>(null);
  const [chainIn, setChainIn] = useState<Chain | null>(null);
  const [chainOut, setChainOut] = useState<Chain | null>(null);
  const [tokenIn, setTokenIn] = useState<any | null>(null);
  const [tokenOut, setTokenOut] = useState<any | null>(null);
  const [amount, setAmount] = useState<string>("");
  const [swapDetails, setSwapDetails] = useState<SwapDetails | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { address, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { open } = useWeb3Modal();
  const [signer, setSigner] = useState(null as any);

  const [tokensByChain, setTokensByChain] = useState<TokensByChain>({});
  const [receivingAddress, setReceivingAddress] = useState<string>("");
  const [showReceivingAddress, setShowReceivingAddress] = useState<boolean>(false);
  const [slippage, setSlippage] = useState<number>(3);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [swapHistory, setSwapHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [expandedSwap, setExpandedSwap] = useState(null);

  const [isChainsFetched, setIsChainsFetched] = useState(false);

  useEffect(() => {
    const init = async () => {
      if (!walletProvider) {
        return;
      }
      const ethersProvider = new BrowserProvider(
        walletProvider as Eip1193Provider
      );
      const tempSigner = await ethersProvider.getSigner();
      setSigner(tempSigner);
    };
    init();
  }, [walletProvider]);

  useEffect(() => {
    fetchChains();
  }, []);

  useEffect(() => {
    if (chainIn) {
      fetchTokensForChain(chainIn.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainIn]);

  useEffect(() => {
    if (chainOut) {
      fetchTokensForChain(chainOut.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainOut]);

  useEffect(() => {
    if (chainIn && chainOut && tokenIn && tokenOut && amount) {
      calculateSwap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainIn, chainOut, tokenIn, tokenOut, amount, receivingAddress]);

  const handleSlippageChange = (event: Event, newValue: number | number[]) => {
    setSlippage(newValue as number);
  };

  const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setAnchorEl(null);
  };

  const fetchChains = async () => {
    try {
      const { data } = await axios.get(`${API_BASE_URL}/chains?${PARTNERID}`);
      setChains(data);
      if (data.length > 0) {
        setChainIn(data[29] || data[0]);
        setChainOut(data[0]);
      }
      setIsChainsFetched(true);
    } catch (error:any) {
      toast.error(error.response?.data?.message || "Failed to fetch supported chains");
      setIsChainsFetched(true);
    }
  };

  const fetchTokensForChain = async (chainId: number) => {
    if (tokensByChain[chainId] && tokensByChain[chainId].length !== 0) return;

    let chainTokens: Token[] = tokens[`${chainId}`] || [];

    try {
      if (chainId === 1329) {
        const params = new URLSearchParams({
          network: "All"
        });

        const headers = {
          "x-api-key": process.env.REACT_APP_AUTH_KEY,
          "x-user-identifier": process.env.REACT_APP_USER_ID,
        };

        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/tokens/launched?${params}`,
          { headers }
        );
        const seiTokens: Token[] = [];
        data.launchedTokens.forEach((item: any) => {
          seiTokens.push({
            address: item.tokenAddress,
            symbol: item.tokenSymbol,
            decimals: 18,
            name: item.tokenName,
            logoURI: item.tokenImage,
            chainId,
          });
        });
        chainTokens = [...seiTokens, ...chainTokens];
      }

      setTokensByChain((prev) => ({...prev, [chainId]: chainTokens}));
    } catch (error: any) {
      setTokensByChain((prev) => ({ ...prev, [chainId]: [] }));
      toast.error(error.response?.data?.message || `Failed to fetch tokens for ${chainId}`);
    }
  };

  const calculateSwap = async () => {
    if (!chainIn || !chainOut || !tokenIn || !tokenOut || !amount) return;
    if (showReceivingAddress && !receivingAddress) return;

    setLoading(true);
    setError(null);

    try {
      const body = {
        tokenAmountIn: {
          address: tokenIn.address,
          amount: ethers.parseUnits(amount, tokenIn.decimals).toString(),
          chainId: chainIn.id,
          decimals: tokenIn.decimals,
          symbol: tokenIn.symbol,
        },
        tokenOut: {
          chainId: chainOut.id,
          address: tokenOut.address,
          symbol: tokenOut.symbol,
          decimals: tokenOut.decimals,
        },
        from: address || ethers.ZeroAddress,
        to: showReceivingAddress && receivingAddress ? receivingAddress : (address || ethers.ZeroAddress),
        slippage: slippage * 100, // % slippage
      }

      console.log("Swap request body", body);
      const { data } = await axios.post(`${API_BASE_URL}/swap?${PARTNERID}`, body);
      console.log("Swap data", data);

      setSwapDetails(data);
    } catch (error:any) {
      toast.error(error.response?.data?.message || "Failed to calculate swap details");
    } finally {
      setLoading(false);
    }
  };

  const connectWallet = async () => {
    if (!isConnected) {
      await open();
    }
  };

  const handleSwap = async () => {
    if (!isConnected || !signer) {
      toast.warn("Please connect your wallet first.");
      return;
    }

    if (!swapDetails) return;

    setLoading(true);
    setError(null);

    try {
      // Approval step (if needed)
      if (swapDetails.approveTo && tokenIn?.address !== ethers.ZeroAddress) {
        const tokenContract = new ethers.Contract(
          tokenIn?.address || "",
          ["function approve(address spender, uint256 amount) returns (bool)"],
          signer
        );
        const approveResponse = await tokenContract.approve(
          swapDetails.approveTo,
          ethers.parseUnits(amount, tokenIn.decimals) || ethers.MaxUint256
        );
        await approveResponse.wait(1);
      }

      // Perform the swap
      const swapResponse = await signer.sendTransaction(swapDetails.tx);
      const receipt = await swapResponse.wait(1);

      await axios.post(`${process.env.REACT_APP_API_URL}/swaps`, {
        transactionHash: receipt.hash,
        fromChainId: chainIn?.id,
        toChainId: chainOut?.id,
        fromAddress: address,
        toAddress: showReceivingAddress && receivingAddress ? receivingAddress : address,
        fromTokenAddress: tokenIn.address,
        fromTokenAmount: parseFloat(amount),
        fromTokenSymbol: tokenIn.symbol,
        fromTokenImage: tokenIn.logoURI,
        toTokenAddress: tokenOut.address,
        toTokenAmount: parseFloat(ethers.formatUnits(
          swapDetails.tokenAmountOut.amount,
          swapDetails.tokenAmountOut.decimals
        )),
        toTokenSymbol: tokenOut.symbol,
        toTokenImage: tokenOut.logoURI,
      }, {
        headers: {
          "x-api-key": process.env.REACT_APP_AUTH_KEY,
          "x-user-identifier": process.env.REACT_APP_USER_ID,
        }
      });

      setAmount("");
      setReceivingAddress("");
      setShowReceivingAddress(false);
      setSwapDetails(null);

      toast.success('Token swapped successfully!');
    } catch (error:any) {
      toast.error(error.response?.data?.message || "Swap failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const renderRouteSteps = () => {
    if (!swapDetails || !swapDetails.route) return null;

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', overflowX: 'auto', py: 2, gap: 1 }}>
        {swapDetails.route.map((token, index) => (
          <React.Fragment key={index + Math.random()}>
            {index > 0 && (
              <ArrowForwardIcon sx={{ color: 'text.secondary', flexShrink: 0 }} />
            )}
            <RouteStep elevation={2}>
              <Avatar
                src={token.logoURI || ""}
                sx={{ width: 15, height: 15, mb: 1 }}
                alt={token.symbol}
              />
              <Typography variant="body2" noWrap>
                {token.symbol}
              </Typography>
              <Typography variant="caption" color="text.secondary" noWrap>
                {chains?.find(c => c.id === token.chainId)?.name}
              </Typography>
            </RouteStep>
          </React.Fragment>
        ))}
      </Box>
    );
  };

  const handleHistoryClick = () => {
    setHistoryDialogOpen(true);
    fetchSwapHistory();
  };

  const fetchSwapHistory = async () => {
    if (!address) {
      toast.warn(`Connect your wallet first.`);
      return;
    };

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/swaps/${address}?page=${currentPage}`, {
        headers: {
          "x-api-key": process.env.REACT_APP_AUTH_KEY,
          "x-user-identifier": process.env.REACT_APP_USER_ID,
        }
      });
      setSwapHistory(response.data.swaps);
      setTotalPages(response.data.totalPages);
    } catch (error:any) {
      toast.error(error.response?.data?.message || `Error fetching your swap history.`);
    }
  };

  const loadMoreHistory = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      fetchSwapHistory();
    }
  };

  const renderSwapHistoryItem = (swap:any) => {
    const isExpanded = expandedSwap === swap.id;

    return (
      <ListItem key={swap.id + Math.random()} divider>
        <ListItemText
          primary={
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Link href={`https://explorer.symbiosis.finance/transactions/${swap.fromChainId}/${swap.transactionHash}`} target="_blank" rel="noopener noreferrer">
                {`${swap.transactionHash.slice(0, 6)}...${swap.transactionHash.slice(-4)}`}
              </Link>
              <Typography>
                {new Date(swap.createdAt).toLocaleString('en-GB', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit'
                })}
              </Typography>
            </Box>
          }
          secondary={
            <Box>
              <Typography>{`${swap.fromTokenSymbol} (${chains?.find(c => c.id === swap.fromChainId)?.name}) → ${swap.toTokenSymbol} (${chains?.find(c => c.id === swap.toChainId)?.name})`}</Typography>
              <Typography>{`From: ${swap.fromAddress.slice(0, 6)}...${swap.fromAddress.slice(-4)}`}</Typography>
              <Collapse in={isExpanded}>
                <Typography>{`To: ${swap.toAddress.slice(0, 6)}...${swap.toAddress.slice(-4)}`}</Typography>
                <Typography>{`Amount: ${swap.fromTokenAmount} ${swap.fromTokenSymbol} → ${swap.toTokenAmount} ${swap.toTokenSymbol}`}</Typography>
              </Collapse>
            </Box>
          }
        />
        <IconButton onClick={() => setExpandedSwap(isExpanded ? null : swap.id)}>
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </ListItem>
    );
  };

  let comingSoon = true;
  if (comingSoon) {
    return (
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        height="100vh"
      >
        <Card>
          <CardContent>
            <Box textAlign="center">
              <Typography variant="h6" sx={{ mt: 2 }}>
                Coming soon...
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>
    )
  }

  if (!isChainsFetched) {
    return (
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        height="100vh"
      >
        <Card>
          <CardContent>
            <Box textAlign="center">
              <CircularProgress size={50} />
              <Typography variant="h6" sx={{ mt: 2 }}>
                Loading chains...
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <StyledCard>
      <CardContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h5" fontWeight="bold">
            SeiFun Bridge
          </Typography>
          <Box>
            <IconButton onClick={handleHistoryClick}>
              <HistoryIcon />
            </IconButton>
            <IconButton onClick={handleSettingsClick}>
              <SettingsIcon />
            </IconButton>
          </Box>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleSettingsClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Box sx={{ p: 2, width: 300 }}>
              <Typography variant="h6" gutterBottom>
                Slippage Tolerance
              </Typography>
              <Slider
                value={slippage}
                onChange={handleSlippageChange}
                aria-labelledby="slippage-slider"
                valueLabelDisplay="auto"
                step={0.1}
                marks
                min={0.1}
                max={5}
              />
              <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                Current slippage: {slippage}%
              </Typography>
            </Box>
          </Popover>
        </Box>

        <Dialog open={historyDialogOpen} onClose={() => setHistoryDialogOpen(false)} maxWidth="md" fullWidth>
          <DialogTitle>Swap History</DialogTitle>
          <DialogContent>
            <List>
              {swapHistory.map(renderSwapHistoryItem)}
            </List>
            {currentPage < totalPages && (
              <Button onClick={loadMoreHistory} fullWidth variant="outlined" sx={{ mt: 2 }}>
                Load More
              </Button>
            )}
          </DialogContent>
        </Dialog>

        <TokenSelect>
          <FormControl fullWidth sx={{ mr: 1 }}>
            <StyledSelect
              value={chainIn ? chainIn.id : ""}
              onChange={(e) => {
                const newChain =
                  chains?.find((c) => c.id === e.target.value) ?? null;
                setChainIn(newChain);
                setTokenIn(null); // Reset token when chain changes
              }}
              variant="standard"
            >
              {chains && chains?.map((chain, index) => (
                <MenuItem key={chain.id + `${index}`} value={chain.id}> 
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography sx={{ fontSize: 18 }}>{chain.name}</Typography>
                    <Avatar
                      sx={{ width: 25, height: 25 }}
                      alt={chain.name}
                      src={chain.icon}
                    />
                  </Box>
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
          <FormControl fullWidth sx={{ mr: 1 }}>
            <StyledSelect
              value={tokenIn ? tokenIn.address : ""}
              onChange={(e) =>
                chainIn &&
                setTokenIn(
                  tokensByChain[chainIn.id].find(
                    (t) => t.address === e.target.value
                  )
                )
              }
              variant="standard"
            >
              {chainIn &&
                tokensByChain[chainIn.id]?.map((token, index) => (
                  <MenuItem key={token.address + `${index}`} value={token.address}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography sx={{ fontSize: 18 }}>
                        {token.symbol}
                      </Typography>
                      <Avatar
                        sx={{ width: 25, height: 25 }}
                        alt={token.name}
                        src={token.logoURI || ""}
                      />
                    </Box>
                  </MenuItem>
                ))}
            </StyledSelect>
          </FormControl>
          <TextField
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            variant="standard"
            placeholder="0.0"
            sx={{ width: "100px" }}
          />
        </TokenSelect>

        <Box display="flex" justifyContent="center" my={2}>
          <IconButton
            onClick={() => {
              const tempChain = chainIn;
              const tempToken = tokenIn;
              setChainIn(chainOut);
              setTokenIn(tokenOut);
              setChainOut(tempChain);
              setTokenOut(tempToken);
            }}
          >
            <SwapVertIcon />
          </IconButton>
        </Box>

        <TokenSelect>
          <FormControl fullWidth sx={{ mr: 1 }}>
            <StyledSelect
              value={chainOut ? chainOut.id : ""}
              onChange={(e) => {
                const newChain =
                  chains?.find((c) => c.id === e.target.value) ?? null;
                setChainOut(newChain);
                setTokenOut(null); // Reset token when chain changes
              }}
              variant="standard"
            >
              {chains?.map((chain) => (
                <MenuItem key={chain.id + Math.random()} value={chain.id}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography sx={{ fontSize: 18 }}>{chain.name}</Typography>
                    <Avatar
                      sx={{ width: 25, height: 25 }}
                      alt={chain.name}
                      src={chain.icon}
                    />
                  </Box>
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
          <FormControl fullWidth sx={{ mr: 1 }}>
            <StyledSelect
              value={tokenOut ? tokenOut.address : ""}
              onChange={(e) =>
                chainOut &&
                setTokenOut(
                  tokensByChain[chainOut.id].find(
                    (t) => t.address === e.target.value
                  )
                )
              }
              variant="standard"
            >
              {chainOut &&
                tokensByChain[chainOut.id]?.map((token, index) => (
                  <MenuItem key={token.address + `${index}`} value={token.address}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography sx={{ fontSize: 18 }}>
                        {token.symbol}
                      </Typography>
                      <Avatar
                        sx={{ width: 25, height: 25 }}
                        alt={token.name}
                        src={token.logoURI || ""}
                      />
                    </Box>
                  </MenuItem>
                ))}
            </StyledSelect>
          </FormControl>
          <Typography variant="body1" sx={{ width: "100px" }}>
            {swapDetails
              ? ethers.formatUnits(
                  swapDetails.tokenAmountOut.amount,
                  swapDetails.tokenAmountOut.decimals
                )
              : "0.0"}
          </Typography>
        </TokenSelect>
        <FormControlLabel
          control={
            <Switch
              checked={showReceivingAddress}
              onChange={(e) => setShowReceivingAddress(e.target.checked)}
            />
          }
          label="Specify a different receiving address"
          sx={{ mt: 2 }}
        />
        <Collapse in={showReceivingAddress}>
          <TextField
            fullWidth
            label="Receiving Address"
            variant="outlined"
            value={receivingAddress}
            onChange={(e) => setReceivingAddress(e.target.value)}
            sx={{ mt: 2 }}
            helperText="Paste the address that will receive the swapped tokens"
          />
        </Collapse>

        <Button
          fullWidth
          variant="contained"
          onClick={isConnected ? handleSwap : connectWallet}
          disabled={loading || !swapDetails}
          sx={{
            mt: 2,
            mb: 2,
            background: "#9E1F63",
            color: "white",
            "&:hover": { background: "#7D1950" },
          }}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : isConnected ? (
            "Swap"
          ) : (
            "Connect Wallet"
          )}
        </Button>

        {error && (
          <Typography color="error" variant="body2" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}

        <Divider sx={{ my: 2 }} />

        {swapDetails && (
          <Box mt={2}>
            <Grid2 container spacing={2}>
              <Grid2 xs={6}>
                <Typography variant="body2">
                  Estimated fee{" "}
                  <Tooltip title="Fee for the cross-chain transfer">
                    <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
                </Typography>
              </Grid2>
              <Grid2 xs={6} display="flex" justifyContent="flex-end">
                <Typography variant="body2">
                  {swapDetails.fee ? ethers.formatUnits(
                    swapDetails.fee?.amount,
                    swapDetails.fee?.decimals
                  ) : 0 }{" "}
                  {swapDetails.fee?.symbol}
                </Typography>
              </Grid2>
              <Grid2 xs={6}>
                <Typography variant="body2">
                  Price Impact{" "}
                  <Tooltip title="Estimated price impact of this swap">
                    <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
                </Typography>
              </Grid2>
              <Grid2 xs={6} display="flex" justifyContent="flex-end">
                <Typography variant="body2">
                  {swapDetails.priceImpact}%
                </Typography>
              </Grid2>
              <Grid2 xs={6}>
                <Typography variant="body2">Estimated time</Typography>
              </Grid2>
              <Grid2 xs={6} display="flex" justifyContent="flex-end">
                <Typography variant="body2">1 - 2 Min</Typography>
              </Grid2>
            </Grid2>

            <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
              Swap Route
            </Typography>
            {renderRouteSteps()}
          </Box>
        )}
      </CardContent>
    </StyledCard>
  );
};

export default SymbiosisBridge;
