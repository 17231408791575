import axios from 'axios';

export async function makeApiRequest(path) {
	try {
    const headers = { 
      'Access-Control-Allow-Origin': '*',
      'x-api-key': process.env.REACT_APP_AUTH_KEY,
      'x-user-identifier': process.env.REACT_APP_USER_ID
    };
		const response = await axios.get(`${process.env.REACT_APP_API_URL}/${path}`, { headers });
		return response.data;
	} catch (error) {
		throw new Error(`CryptoCompare request error: ${error}`);
	}
}

export function getResolutionInMs(resolution) {
  const resolutionMap = {
    '1': 60 * 1000,        // 1 minute
    '5': 5 * 60 * 1000     // 5 minutes
  };
  return resolutionMap[resolution] || 60 * 1000;
}

export function generateExtendedChartData(baseData, totalMinutes = 1000) {
  const result = [];
  let lastDate = baseData[baseData.length - 1].date;
  let lastPrice = parseFloat(baseData[baseData.length - 1].tokenPrice);

  for (let i = 0; i < totalMinutes; i++) {
    const minuteStart = lastDate;
    let minuteHigh = lastPrice;
    let minuteLow = lastPrice;
    let minuteClose = lastPrice;

    // Generate 10 price points within each minute
    for (let j = 0; j < 10; j++) {
      const secondsOffset = j * 6; // Spread 10 points over 60 seconds
      const time = minuteStart + secondsOffset;
      
      // Generate a new price with some randomness
      const randomChange = (Math.random() - 0.5) * 0.1e-9; // Adjust this value for desired volatility
      let newPrice = lastPrice + randomChange;
      
      // Ensure the price doesn't go below 6e-9 or above 7.5e-9
      newPrice = Math.max(6e-9, Math.min(7.5e-9, newPrice));
      
      result.push({
        tokenPrice: newPrice,
        date: time
      });

      // Update minute high and low
      minuteHigh = Math.max(minuteHigh, newPrice);
      minuteLow = Math.min(minuteLow, newPrice);
      
      // The last price in the minute becomes the closing price
      if (j === 9) {
        minuteClose = newPrice;
      }

      lastPrice = newPrice;
    }

    // Add a summary point for the entire minute (useful for 1-minute candles)
    result.push({
      tokenPrice: minuteClose,
      date: minuteStart + 59, // End of the minute
      open: result[result.length - 10].tokenPrice, // First price of the minute
      high: minuteHigh,
      low: minuteLow,
      close: minuteClose
    });

    lastDate += 60; // Move to the next minute
  }

  return result;
}

export function transformBaseData(baseData, resolution) {
  const bars = [];
  const resolutionMs = getResolutionInMs(resolution);
  let currentBar = null;
  let currentBarStartTime = 0;

  baseData.forEach((item) => {
    const time = item.date * 1000; // Convert to milliseconds
    const price = parseFloat(item.tokenPrice);

    // Check if we need to start a new bar
    if (!currentBar || time >= currentBarStartTime + resolutionMs) {
      if (currentBar) {
        bars.push(currentBar);
      }
      currentBarStartTime = Math.floor(time / resolutionMs) * resolutionMs;
      currentBar = {
        time: currentBarStartTime,
        open: price,
        high: price,
        low: price,
        close: price
      };
    } else {
      // Update the current bar
      currentBar.high = Math.max(currentBar.high, price);
      currentBar.low = Math.min(currentBar.low, price);
      currentBar.close = price;
    }
  });

  // Push the last bar if it exists
  if (currentBar) {
    bars.push(currentBar);
  }

  return bars;
}
