import { generateExtendedChartData, makeApiRequest, transformBaseData } from './helpers';
import { subscribeOnStream, unsubscribeFromStream } from './streaming';

const lastBarsCache = new Map();

const configurationData = {
  supported_resolutions: ['1', '5'],
  exchanges: [
    { value: 'SEIFUN', name: 'SEIFUN', desc: 'SEIFUN Decentralized Exchange' },
  ],
  symbols_types: [{ name: 'crypto', value: 'crypto' }],
};

export function initialDatafeed(tokenInfo) {
  return {
    onReady: (callback) => {
      setTimeout(() => callback(configurationData));
    },

    searchSymbols: async (userInput,
      exchange,
      symbolType,
      onResultReadyCallback,) => {
      
    },

    resolveSymbol: async (
      symbolName,
      onSymbolResolvedCallback,
      onResolvedErrorCallback
    ) => {

      const symbolInfo = {
        ticker: `${tokenInfo.network}:${tokenInfo.tokenSymbol}`,
        name: tokenInfo.tokenName,
        description: tokenInfo.tokenName,
        type: 'crypto',
        session: '24x7',
        timezone: 'Etc/UTC',
        exchange: 'SEIFUN',
        minmov: 1,
        pricescale: 1000000000000, // Increased precision for small values
        has_intraday: true,
        visible_plots_set: true,
        has_weekly_and_monthly: true,
        supported_resolutions: configurationData.supported_resolutions,
        volume_precision: 2,
        data_status: 'streaming',
        address: tokenInfo.tokenAddress,
        network: tokenInfo.network,
      };

      setTimeout(() => onSymbolResolvedCallback(symbolInfo));
    },

    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
      const { from, to, firstDataRequest } = periodParams;
    
      try {
        const resp = await makeApiRequest(
          `tokens/${symbolInfo.network}/${symbolInfo.address}`
        );
        if (!resp.chartList || resp.chartList.length === 0) {
          onHistoryCallback([], { noData: true });
          return;
        }
    
        // Transform the base data
        const transformedBars = transformBaseData(resp.chartList, resolution);
    
        // Optionally generate synthetic data
        const useSyntheticData = false; // Set this to true when you want to use synthetic data
        let bars = transformedBars;
    
        if (useSyntheticData) {
          const extendedChartList = generateExtendedChartData(resp.chartList, 10049);
          bars = transformBaseData(extendedChartList, resolution);
        }
    
        // Filter bars based on the requested time range
        const filteredBars = bars.filter(bar => bar.time >= from * 1000 && bar.time <= to * 1000);

        if (firstDataRequest) {
          lastBarsCache.set(symbolInfo.ticker, { ...filteredBars[filteredBars.length - 1] });
        }
    
        onHistoryCallback(filteredBars, { noData: filteredBars.length === 0 });
      } catch (error) {
        onErrorCallback(error);
      }
    },
    
    subscribeBars: (
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscriberUID,
      onResetCacheNeededCallback
    ) => {
      subscribeOnStream(
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscriberUID,
        onResetCacheNeededCallback,
        lastBarsCache.get(symbolInfo.ticker)
      );
    },

    unsubscribeBars: (subscriberUID) => {
      unsubscribeFromStream(subscriberUID);
    },
  };
}
