import { useEffect, useRef } from 'react';
import {
	widget,
	ChartingLibraryWidgetOptions,
	ResolutionString,
} from '../../charting_library';
import * as React from 'react';
import { initialDatafeed } from '../../utils/trading-view/datafeed';

export interface ChartContainerProps {
	symbol: ChartingLibraryWidgetOptions['symbol'];
	interval: ChartingLibraryWidgetOptions['interval'];

	libraryPath: ChartingLibraryWidgetOptions['library_path'];
	chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'];
	chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version'];
	clientId: ChartingLibraryWidgetOptions['client_id'];
	userId: ChartingLibraryWidgetOptions['user_id'];
	fullscreen: ChartingLibraryWidgetOptions['fullscreen'];
	autosize: ChartingLibraryWidgetOptions['autosize'];
	studiesOverrides: ChartingLibraryWidgetOptions['studies_overrides'];
	container: ChartingLibraryWidgetOptions['container'];
}

interface TVChartContainerProps {
	tokenInfo: any;
}

export const TVChartContainer = ({ tokenInfo }: TVChartContainerProps) => {
	const chartContainerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;

	useEffect(() => {
		const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: `${tokenInfo.network}:${tokenInfo.tokenName}`,
      datafeed: initialDatafeed(tokenInfo),
      interval: '1' as ResolutionString,
      container: chartContainerRef.current,
      library_path: '/charting_library/',
      fullscreen: false,
      studies_overrides: {},
      time_frames: [
        { text: '5d', resolution: '5' as ResolutionString, description: '5 Day', title: '5d' },
        { text: '1d', resolution: '1' as ResolutionString, description: '1 Day', title: '1d' },
      ],
      timeframe: { from: 1722398387, to: Date.now() },
      disabled_features: [
        'use_localstorage_for_settings',
        'header_saveload',
        'save_shortcut',
        'header_indicators'
      ],
      enabled_features: ['study_templates'],
      charts_storage_url: 'https://saveload.tradingview.com',
      charts_storage_api_version: '1.1',
      client_id: 'tradingview.com',
      user_id: 'public_user_id',
      theme: 'dark',
      width: chartContainerRef.current.clientWidth,
			locale: 'en',
    };

		const tvWidget = new widget(widgetOptions);

		tvWidget.onChartReady(() => {
			tvWidget.headerReady().then(() => {
				const button = tvWidget.createButton();
				button.setAttribute('title', 'Click to show a notification popup');
				button.classList.add('apply-common-tooltip');
				button.addEventListener('click', () => tvWidget.showNoticeDialog({
						title: 'Notification',
						body: 'TradingView Charting Library API works correctly',
						callback: () => {
							console.log('Noticed!');
						},
					}));
				button.innerHTML = 'Check API';
			});
		});

		return () => {
			tvWidget.remove();
		};
	});

	return (
		<div
			ref={ chartContainerRef }
			className={ 'TVChartContainer' }
		/>
	);
};
