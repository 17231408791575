import { io } from "socket.io-client";

const SERVER_URL = `${process.env.REACT_APP_API_URL}`; 


const socketOptions: any = {
    autoConnect: true,
    reconnectionDelayMax: 1000,
    auth: {
        apiKey: process.env.REACT_APP_AUTH_KEY,
        userIdentifier: process.env.REACT_APP_USER_ID 
    }
};

export const socket = io(SERVER_URL, socketOptions);