import { Accordion, AccordionDetails, AccordionSummary, Avatar, Slide, Box, Button, FormControl, MenuItem, Select, SelectChangeEvent, styled, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Card, CardContent, Paper, IconButton, InputBase, Divider, Skeleton } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AvaxIcon from '../assets/images/coin/Avax.svg';
import BaseIcon from '../assets/images/coin/Coinbase.svg';
import ArbitrumIcon from '../assets/images/coin/Arbitrum.svg';
import EthereumIcon from '../assets/images/coin/eth.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { TransitionProps } from '@mui/material/transitions';
import axios from "axios";
import { Image } from "@mui/icons-material";
import { useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider, useSwitchNetwork } from "@web3modal/ethers/react";
import { BrowserProvider, Contract, Eip1193Provider, ethers, formatUnits } from 'ethers';
import { socket } from "../utils/socket";
import { getContractAddress } from "./detailsPage";
import { getContractABI } from "./detailsPage";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface PresignedPostData {
    url: string;
    fields: {
      key: string;
      bucket: string;
      'X-Amz-Algorithm': string;
      'X-Amz-Credential': string;
      'X-Amz-Date': string;
      Policy: string;
      'X-Amz-Signature': string;
    };
  }

interface AWSS3 {
    presignedPostData: PresignedPostData;
    fileUrl: string;
    fileKey: string;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const pinataApiKey = 'a12ebd397a8205ca54b3'; 
const pinataSecretApiKey = '565cbfb83b0519ea96fa77bcc0d1cf28e7cbd1e1ca8d0162f41014ed3a0bc975';

export default function CreateCurvePage() {
    const [network, setNetwork] = React.useState('Sei');
    const [deployModal, setDeployModal] = React.useState(false);
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const { open } = useWeb3Modal();
    const { switchNetwork } = useSwitchNetwork()
    const navigate = useNavigate(); 

    const [coinName, setCoinName] = React.useState("");
    const [coinTicker, setCoinTicker] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [imageLink, setImageLink] = React.useState("");
    const [file, setFile] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [telegramLink, setTelegramLink] = React.useState('');
    const [twitterLink, setTwitterLink] = React.useState('');
    const [webLink, setWebLink] = React.useState('');
    const [signer, setSigner] = useState(null as any);
    const [fairLaunchContract, setFairLaunchContract] = React.useState(null as any);
    const [nativeBalanceString, setNativeBalanceString] = React.useState("");
    const [initBuyAmount, setInitBuyAmount] = React.useState('0');
    const [isImageUploading, setIsImageUploading] = useState(false);

    useEffect(() => {
        const init = async () => {
            if (!walletProvider) {
                return;
            }
            const ethersProvider = new BrowserProvider(walletProvider as Eip1193Provider)
            const tempSigner = await ethersProvider.getSigner();
            setSigner(tempSigner);
            const realAddress = getContractAddress("BychainId", chainId);
            const realABI = getContractABI("BychainId", chainId);
            const tempFairLaunchContract = new ethers.Contract(realAddress, realABI, tempSigner);
            setFairLaunchContract(tempFairLaunchContract);
            const nativeBalance = await tempSigner.provider.getBalance(tempSigner.address);
            const tempNativeBalanceString = ethers.formatEther(nativeBalance);
            setNativeBalanceString(tempNativeBalanceString);
        }
        init();
    }, [chainId, address])

    const handleClickOpen = () => {
        setDeployModal(true);
    };

    const tokenDeploy = async () => {
        if (!isConnected) {
            toast.error('Please connect wallet!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            return;
        }

        try {
            // from initial initBuyAmount (ex. 0.001 ETH), estimate tokenInitialAmount
            const contractTotalSupply = 10 ** 9;
            const initAmountFloat = parseFloat(initBuyAmount);
            if (initAmountFloat > 10000) {  // Initial amount limit
                toast.error('Initial buy amount must be less than 10000 SEI!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                });
                return;
            }
            const txHashInit = await fairLaunchContract.createToken(coinName, coinTicker, { value: ethers.parseEther(initBuyAmount) }); //set token initial amount
            const txHash = await txHashInit.wait();
            const tokenCreatedEvent = txHash?.logs[0];
            const tokenAddress = tokenCreatedEvent.address;

            const tokenMarketCap = BigInt(await fairLaunchContract.getTokenMarketCap(tokenAddress));
            const tokenPriceBigInt = BigInt(await fairLaunchContract.getPrice(tokenAddress));
            const ethPriceBigInt = BigInt(await fairLaunchContract.getSEIPriceByUSD());
            const tokenPriceETH = Number(tokenPriceBigInt) / Math.pow(10, 18);
            const ethPriceUSD = Number(ethPriceBigInt) / Math.pow(10, 6);
            const tokenPriceUSD = tokenPriceETH * ethPriceUSD;
            const body = {
                type: "createToken",
                data: {
                    tokenName: coinName,
                    tokenSymbol: coinTicker,
                    tokenDescription: description,
                    tokenAddress: tokenAddress,
                    tokenImage: imageLink,
                    marketcap: tokenMarketCap.toString(),
                    price: tokenPriceUSD.toString(),
                    virtualLP: ethPriceUSD.toString(),
                    creatorAddress: signer.address,
                    contractAddress: getContractAddress("BychainId", chainId),
                    network: network,
                    replies: 0,
                    telegramLink: telegramLink,
                    twitterLink: twitterLink,
                    webLink: webLink
                }
            }

            await new Promise((resolve, reject) => {
                socket.emit("message", JSON.stringify(body), (response: {success: boolean, error?: string}) => {
                    if (response.success) {
                        resolve(response);
                    } else {
                        reject(new Error(response.error));
                    }
                });
            });

            setDeployModal(false);
            toast.success('Token Created!!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            navigate(`/${network}/${tokenAddress}`);
        } catch (e: any) {
            console.log("create token error-----", e.message);
            toast.error('Create a token failed', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            return;
        }
    };

    const handleClose = () => {
        setDeployModal(false);
    }

    const handleChange = (event: SelectChangeEvent) => {
        let chainId = 11155111;
        switch (event.target.value) {
            case "Ethereum":
                chainId = 1
                break;
            case "Avax":
                chainId = 43114
                break;
            case "Arbitrum":
                chainId = 42161
                break;
            case "Base":
                chainId = 8453
                break;
            case "Sepolia":
                chainId = 11155111
                break;
            case "Sei":
                chainId = 1329
                break;
            default:
                break;
        }
        switchNetwork(chainId);
        setNetwork(event.target.value);
    };

    const uploadImageToS3 = async (file: File, presignedPostData: PresignedPostData): Promise<string | null> => {
        const formData = new FormData();

        // Add the presigned POST fields to the FormData object
        Object.entries(presignedPostData.fields).forEach(([key, value]) => {
          formData.append(key, value);
        });

        // Add the file to the FormData object
        formData.append('file', file, presignedPostData.fields.key);
        
        try {
          const response = await axios.post(presignedPostData.url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'x-api-key': process.env.REACT_APP_AUTH_KEY,
            'x-user-identifier': process.env.REACT_APP_USER_ID
            }
          });
      
          if (response.status === 204) {
            return `https://${presignedPostData.fields.bucket}.s3.amazonaws.com/${presignedPostData.fields.key}`;
          } else {
            console.error('Unexpected response status:', response.status);
            return null;
          }
        } catch (error) {
          console.error('Error uploading file:', error);
          return null;
        }
      };

    const onFileChange = (event: any) => {
        setIsLoading(true)
        setFile(event.target.files[0]);
        onFileUpload(event.target.files[0]);
    };

    const onFileUpload = async (file: File) => {
        setIsImageUploading(true);
        try {
          const { presignedPostData, fileUrl } = await getPresignedPostData();
          const imageUrl = await uploadImageToS3(file, presignedPostData);
          if (imageUrl) {
            setImageLink(fileUrl);
            setIsLoading(false);
            toast.success('Image uploaded successfully!');
          } else {
            throw new Error('Failed to upload image');
          }
        } catch (error) {
          console.error('Error uploading image:', error);
          if (error instanceof Error) {
            toast.error(`Failed to upload image: ${error.message}`);
          } else {
            toast.error('An unexpected error occurred while uploading the image.');
          }
        } finally {
          setIsImageUploading(false);
        }
      }; 
      
      const getPresignedPostData = async (): Promise<AWSS3> => {
        const headers = { 
            'Access-Control-Allow-Origin': '*',
            'x-api-key': process.env.REACT_APP_AUTH_KEY,
            'x-user-identifier': process.env.REACT_APP_USER_ID
          };
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/misc/presigned-url`, { headers });
        return response.data;
      };

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });



    return (
        <Box>
            <Grid2 container spacing={2}>
                <Grid2 xs={12} sm={6} mx='auto'>
                    <Box sx={{ width: '80%', marginLeft: 'auto', marginRight: 'auto', marginTop: '1rem' }}>
                        <FormControl fullWidth sx={{ mb: '1rem' }}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={network}
                                onChange={handleChange}
                            >
                                <MenuItem value='Sei' >
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                        <Typography sx={{ fontSize: 18 }}>Sei</Typography>
                                        <Avatar sx={{ width: 25, height: 25 }} alt="avatar" src={"/networks/Sei.svg"} />
                                    </Box>
                                </MenuItem>
                                {/* <MenuItem value='Sepolia' >
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                        <Typography sx={{ fontSize: 18 }}>Sepolia</Typography>
                                        <Avatar sx={{ width: 25, height: 25 }} alt="avatar" />
                                    </Box>
                                </MenuItem> */}
                                {/* <MenuItem value='Ethereum' >
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                        <Typography sx={{ fontSize: 18 }}>Ethereum</Typography>
                                        <Avatar src={EthereumIcon} sx={{ width: 25, height: 25 }} alt="avatar" />
                                    </Box>
                                </MenuItem>
                                <MenuItem value='Avax' >
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                        <Typography sx={{ fontSize: 18 }}>Avax</Typography>
                                        <Avatar src={AvaxIcon} sx={{ width: 25, height: 25 }} alt="avatar" />
                                    </Box>
                                </MenuItem>
                                <MenuItem value='Arbitrum' >
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                        <Typography sx={{ fontSize: 18 }}>Arbitrum</Typography>
                                        <Avatar src={ArbitrumIcon} sx={{ width: 25, height: 25 }} alt="avatar" />
                                    </Box>
                                </MenuItem>
                                <MenuItem value='Base' >
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                        <Typography sx={{ fontSize: 18 }}>Base</Typography>
                                        <Avatar src={BaseIcon} sx={{ width: 25, height: 25 }} alt="avatar" />
                                    </Box>
                                </MenuItem> */}
                            </Select>
                        </FormControl>
                        <Box display='flex' alignItems='center' justifyContent='space-between' mb="1rem">
                            <Typography sx={{ fontSize: { sm: 36, xs: 20 } }}>Create a new Curve</Typography>
                            <Link to="/"><Typography sx={{ fontSize: { sm: 36, xs: 20 }, color: '#FFA800', textDecoration: 'none' }}>Go Back</Typography></Link>
                        </Box>
                        <TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Coin Name" variant="outlined" fullWidth value={coinName} onChange={(e) => setCoinName(e.target.value)} />
                        <TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Coin Ticker" variant="outlined" fullWidth value={coinTicker} onChange={(e) => setCoinTicker(e.target.value)} />
                        <TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Description" variant="outlined" multiline rows={4} fullWidth value={description} onChange={(e) => setDescription(e.target.value)} />
                        {imageLink !== "" && <Avatar src={`${imageLink}`} sx={{ width: 100, height: 100, mb: '1rem' }} alt="image" />}
                        {isLoading === true && <Skeleton variant="circular" width={100} height={100} sx={{ mb: '1rem' }} />}
                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon />}
                        >
                            Image file
                            <VisuallyHiddenInput type="file" onChange={onFileChange} />
                        </Button>

                        <Accordion sx={{ background: 'transparent', boxShadow: 'none' }}>
                            <AccordionSummary sx={{ color: '#00A79D' }}>
                                More Options
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Telegram Link" value={telegramLink} onChange={(e) => setTelegramLink(e.target.value)} variant="outlined" fullWidth />
                                <TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Twitter Link" value={twitterLink} onChange={(e) => setTwitterLink(e.target.value)} variant="outlined" fullWidth />
                                <TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Website Link" value={webLink} onChange={(e) => setWebLink(e.target.value)} variant="outlined" fullWidth />
                            </AccordionDetails>
                        </Accordion>
                        {/* <Button variant="contained" sx={{ background: '#9E1F63', color: 'white' }} fullWidth onClick={handleClickOpen}>Deploy</Button> */}
                        <Button variant="contained" sx={{ background: '#9E1F63', color: 'white' }} fullWidth onClick={handleClickOpen} disabled={isImageUploading}>{isImageUploading ? 'Uploading Image...' : 'Deploy'}</Button>
                        <Typography sx={{ fontSize: { sm: 18, xs: 16 }, my: '1rem' }}>Deploy Fee ()</Typography>
                    </Box>
                </Grid2>
            </Grid2>
            <Dialog
                open={deployModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Create & Buy?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" mb='1rem'>
                        Choose how many sd you want to buy (optional) Buying a small amount of coins helps encourage sales and improves visability of your token.
                    </DialogContentText>
                    <Card>
                        <CardContent>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} mb='0.5rem'>
                                <Typography>Spend</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}><Typography mr='1rem'>Balance: {nativeBalanceString}</Typography> <Typography sx={{ color: '#FFA800' }}>MAX</Typography></Box>
                            </Box>
                            <Paper
                                component="form"
                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', border: '1px solid' }}
                            >
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    inputProps={{ 'aria-label': 'search google maps' }}
                                    value={initBuyAmount}
                                    onChange={(e) => setInitBuyAmount(e.target.value)}
                                />
                                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
                                    <Avatar src={`/networks/${network}.svg`} sx={{ width: 25, height: 25 }} />
                                </IconButton>
                            </Paper>
                        </CardContent>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" sx={{ border: '1px solid #FF0000', background: '#FF000020', color: 'white' }} onClick={handleClose}>Cancel</Button>
                    <Button variant="outlined" sx={{ border: '1px solid #FFA800', background: '#FFA80020', color: 'white' }} onClick={tokenDeploy}>Okay</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}